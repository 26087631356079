import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo partners.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'

const Header = () => {

  const mobile = window.innerWidth<=768 ? true: false
  const [menuOpend, setMenuOpend] = useState(false)

  return (
    <div className='header'>
        <img src={Logo} alt='' className='logo'/>
        {menuOpend === false && mobile ===true ? (
          <div style={{backgroundColor: 'var(--appColor)', padding:'0.5rem', borderRadius: '5px'}}
          onClick={()=> setMenuOpend(true)}
          >
            <img src={Bars} alt=""  style={{width: '1.5rem', height: '1.5rem'}}
            />
          </div>
        ):(
          <ul className="header-menu">
            <li><Link 
            onClick={()=>setMenuOpend(false)}
            activeClass="active"
            to="header"  
            span={true}
            smooth={true}
            >Qui sommes-nous?</Link></li>

            <li><Link 
            onClick={()=>setMenuOpend(false)}
            activeClass="active"
            to="programs"  
            span={true}
            smooth={true}
            >Vente Véhicules</Link></li>

            <li> <Link 
            onClick={()=>setMenuOpend(false)}
            to='reasons'
            span={true}
            smooth={true}
            >Agroalimentaire</Link></li>

            <li><Link 
            onClick={()=>setMenuOpend(false)}
            to='plan'
            span={true}
            smooth={true}
            >Sécurité</Link></li>

            <li><Link 
            onClick={()=>setMenuOpend(false)}
            to='testimonials'
            span={true}
            smooth={true}
            >Contactez-nous</Link></li>
        </ul>
        )

        }
       
    </div>
  )
}

export default Header