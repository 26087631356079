import React from 'react'
import './Hero.css'
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import heart from "../../assets/heart.png"
import calories from "../../assets/calories.png"
import { motion } from "framer-motion"
import NumberCounter from "number-counter"

const Hero = () => {

    const transition = {type: 'spring', duration:3}
    const mobile = window.innerWidth <= 768 ? true: false

  return (
    <div className='hero' id="home">
        <div className="blur2 hero-blur"></div>
        <div className="left-h">
         
            {/* the best ad */}
            <div className="the-best-ad">
                <motion.div
                initial={{left: mobile? "165" :'238px'}}
                whileInView={{left: '8px'}}
                transition={{...transition, type: 'tween'}}
                >

                </motion.div>
                <span>Le parténaire qu'il vous faut</span>
            </div>
            {/* hero header*/}
            <div className='hero-text'>
                <div>
                    <span className='stroke-text2'>Partenar's</span>
                    <span> Universal</span>
                </div>
               
                <div>
                    <span>
                        Industries
                    </span>
                </div>
                <div className="">
                    <span className='text-presentation'>
                    Nous sommes une entreprise de droit ivoirien Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vulputate nisi quis urna tincidunt viverra. Cras consectetur mollis tempus. Nulla facilisi. Fusce vitae fermentum turpis, ac semper purus. Mauris non interdum dolor. Nullam a nisi bibendum ex maximus laoreet. Mauris aliquet, nulla sit amet bibendum laoreet, ex sem semper nisl, ut blandit erat sem in purus. Quisque non ante suscipit, accumsan elit sit amet, viverra justo. Morbi sed sapien libero
                    </span>
                </div>
            </div>
             {/* figures*/}
             <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay='4' prefix="+" />
                    </span>
                    <span>Employés</span>
                </div>
                <div>
                    <span><NumberCounter end={978} start={900} delay='4' prefix="+" /></span>
                    <span>Parténaires</span>
                </div>
                <div>
                    <span><NumberCounter end={50} start={10} delay='4' prefix="+" /></span>
                    <span>Réalisations</span>
                </div>
             </div>
             {/* hero buttons */}
             <div className="hero-buttons">
                <button className="btn" style={{fontWeight:'bolder', fontSize:'1.2rem'}}>
                    Démarrons
                </button>
                <button className="btn" style={{fontWeight:'bolder', fontSize:'1.2rem'}}>
                    Plus d'infos
                </button>
             </div>
        </div>
        <div className="right-h">
            <button className="btn">Contact </button>
            <motion.div
            initial={{right:"-1rem"}}
            whileInView={{right: "4rem"}} 
            transition={transition} 
            className="heart-rate">
                
                <span>Nous avons </span>
                <span>les meilleurs</span>
            </motion.div>
            {/* hero buttons */}
            <img src={hero_image} alt="" className='hero-image'/>
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right: '20rem'}}
            transition={transition}
            src={hero_image_back} alt="" className='hero-image-back'/>
            {/* calories */}
            
        </div>
    </div>
  )
}

export default Hero