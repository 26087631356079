import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/hero/Hero';
import Join from './components/join/Join';

import Programs from './components/programs/Programs';
import Reason from './components/reasons/Reason';
import Separateur from './components/Separateur';
import Testimonials from './components/Testimonials/Testimonials';

function App() {
  return (
    <div className="App">
          <Header />
          <Separateur />
          <Hero />
          <Programs />
          <Reason />     
          <Testimonials />
          <Join />
          <Footer />
    </div>
  );
}

export default App;
